import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import router from "@/router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    // Initialize Axios interceptors
    ApiService.mountInterceptors();
  }
// Add interceptors
  private static mountInterceptors(): void {
    let isRefreshing = false;
    let refreshSubscribers: Array<(token: string) => void> = [];
    const onRefreshed = (token: string) => {
      refreshSubscribers.forEach((callback) => callback(token));
      refreshSubscribers = [];
    };
    const subscribeTokenRefresh = (callback: (token: string) => void) => {
      refreshSubscribers.push(callback);
    };

    ApiService.vueInstance.axios.interceptors.request.use(
        (config: AxiosRequestConfig) => {
          const token = JwtService.getToken();
          if (token) {
            config.headers["x-auth-token"] = `${token}`;
          }
          return config;
        },
        (error) => Promise.reject(error)
    );

    ApiService.vueInstance.axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;
          if (error.response && error.response.status === 401 && !originalRequest._retry) {
            // Token expired (401) and the request has not been retried yet
            originalRequest._retry = true;

            try {
              // Request a new token using the refresh token from the cookies
              const response = await ApiService.post("/admin/auth/refreshToken", {});// No need to send refresh token explicitly

              const data = response.data;
              const token = data['x-auth-token'];
              // Adjust according to your API response
              // Save the new token (you may need to update the cookie as well)
              JwtService.saveToken(data);

              // Set the new token in the headers and retry the original request
              originalRequest.headers["x-auth-token"] = token;
              return axios(originalRequest);
            } catch (refreshError) {
              // Handle refresh token failure (e.g., redirect to login)
              // return Promise.reject(refreshError);
              router.push({ name: "sign-in" });
            }
          }

          // For all other errors, just reject the promise
          return Promise.reject(error);
        }
    );
  }
  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "x-auth-token"
    ] = `${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params, {withCredentials: true});
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  // UPLOAD PHOTO
  public static upload(
      resource: string,
      slug: string,
      formData: FormData
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, formData);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
