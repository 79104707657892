import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

export interface User {
  id: number,
  uname: string,
  pswd: string,
  role_id: number,
  email: string,
  status: string
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  // userDetail: object;
  isAuthenticated: boolean;
  activationData: unknown;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  // userDetailData = {};
  isAuthenticated = !!JwtService.getToken();
  activationData = {};

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  // get getUserDetail() {
  //   return this.userDetailData;
  // }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get activation data
   * @returns object
   */
  get getActivationData() {
    return this.activationData;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user.profile;
    this.errors = {};
    JwtService.saveToken(user);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_ACTIVATION_DATA](activationData) {
    this.activationData = activationData;
  }
  //
  // @Mutation
  // [Mutations.SET_PASSWORD](password) {
  //   this.user.password = password;
  // }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }
  //
  // @Mutation
  // [Mutations.SET_USER_DETAIL](user) {
  //   this.userDetailData = user;
  //   this.errors = [];
  // }

  @Action
  [Actions.GET_PROFILE]() {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.get("/admin/users/getProfile")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }
  @Action
  [Actions.LOGIN](credentials) {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.post("/admin/auth/login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.meta.message);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.post("/users", credentials)
      .then(({ data }) => {
        // this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.ADD_USER](credentials) {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.post("/v2/user", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({response}) => {
        this.context.commit(Mutations.SET_ERROR, response.data.meta.message);
      });
  }

  @Action
  [Actions.GET_USERS]() {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.get("/admin/users")
      .then(({ data }) => {
        return data;
        // this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }
  //
  // @Action
  // [Actions.UPDATE_PROFILE](payload) {
  //   this.context.commit(Mutations.SET_ERROR, null);
  //   return ApiService.update("/users", payload.index_id, payload)
  //     .then(({ data }) => {
  //       this.context.commit(Mutations.SET_USER_DETAIL, data);
  //       return data;
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, response.data.error);
  //     });
  // }
  // @Action
  // [Actions.UPDATE_USER](data) {
  //   this.context.commit(Mutations.SET_USER_DETAIL, data);
  // }
  //
  // @Action
  // [Actions.UPLOAD_PROFILE](payload) {
  //   this.context.commit(Mutations.SET_ERROR, null);
  //   return ApiService.upload("/users", payload.index_id, payload.formData)
  //     .then(({ data }) => {
  //       this.context.commit(Mutations.SET_USER, data);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, response.data.error);
  //     });
  // }

  @Action
  [Actions.DELETE_PROFILE_PHOTO](payload) {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.update("/users/deletePhoto", payload.fileName, payload)
        .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    this.context.commit(Mutations.SET_ERROR, null);
    this.context.commit(Mutations.PURGE_AUTH);
    return ApiService.post("auth/forgot-password", payload)
      .then(({ data })  => {
        this.context.commit(Mutations.SET_ACTIVATION_DATA, data.data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.VERIFY_CODE](payload) {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.post("auth/verify-code", payload)
      .then(({ data })  => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.post("auth/reset-password", payload)
      .then(({ data })  => {
        // this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      store.dispatch(Actions.GET_PROFILE);

      // ApiService.post("verify_token", payload)
      //   .then(({ data }) => {
      //     this.context.commit(Mutations.SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
      //     this.context.commit(Mutations.PURGE_AUTH);
      //   });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_ADMIN_MATCHES_USERS]() {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.get("/users/recommended")
        .then(({ data })  => {
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        });
  }

  @Action
  [Actions.GET_MATCHED_USERS]() {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.get("/users/matches")
        .then(({ data })  => {
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        });
  }

  @Action
      [Actions.GET_MATCHES]({id, payload}) {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.query(`/admin/users/matches/${id}`, { params: payload })
        .then((response)  => {
          return response;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        });
  }
@Action
    [Actions.GENERATE_USER_TOKEN]({index_id}) {
  this.context.commit(Mutations.SET_ERROR, null);
  return ApiService.get(`/admin/auth/generateUserToken?index_id=${index_id}`)
      .then(({ data }) => {
        return data;
        // this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
}
  // @Action
  // [Actions.USER_DETAIL](id) {
  //   this.context.commit(Mutations.SET_ERROR, null);
  //   return ApiService.get(`/admin/users/${id}`)
  //       .then(({ data })  => {
  //         this.context.commit(Mutations.SET_USER_DETAIL, data);
  //         return data;
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.error);
  //       });
  // }
}
