import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import SearchModule from "@/store/modules/SearchModule";
import ReligionModule from "@/store/modules/ReligionModule";
import CasteModule from "@/store/modules/CasteModule";
import MtongueModule from "@/store/modules/MtongueModule";
import CountryModule from "@/store/modules/CountryModule";
import StateModule from "@/store/modules/StateModule";
import EduDetailModule from "@/store/modules/EduDetailModule";
import OccupationModule from "@/store/modules/OccupationModule";
import DesignationModule from "@/store/modules/DesignationModule";
import PlanModule from "@/store/modules/PlanModule";
import PaymentMethodModule from "@/store/modules/PaymentMethodModule";
import PaymentModule from "@/store/modules/PaymentModule";
import ContactModule from "@/store/modules/ContactModule";
import AWSModule from "@/store/modules/AWSModule";
import SiteModule from "@/store/modules/SiteModule";
import DiscoverModule from "@/store/modules/DiscoverModule";
import UserModule from "@/store/modules/UserModule";
import MatchActivityModule from "@/store/modules/MatchActivityModule";
import MatchedStatusModule from "@/store/modules/MatchedStatusModule";
import MembershipPlansModule from "@/store/modules/MembershipPlansModule";
import RoleModule from "@/store/modules/RoleModule";
import PermissionModule from "@/store/modules/PermissionModule";
import LeadModule from "@/store/modules/LeadModule";
import AdminModule from "@/store/modules/AdminModule";
import TimezoneModule  from "@/store/modules/TimezoneModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    SearchModule,
    ReligionModule,
    CasteModule,
    MtongueModule,
    CountryModule,
    StateModule,
    EduDetailModule,
    OccupationModule,
    DesignationModule,
    PlanModule,
    PaymentMethodModule,
    PaymentModule,
    ContactModule,
    AWSModule,
    SiteModule,
    DiscoverModule,
    UserModule,
    MatchActivityModule,
    MatchedStatusModule,
    MembershipPlansModule,
    RoleModule,
    PermissionModule,
    LeadModule,
    AdminModule,
    TimezoneModule
  },
});

export default store;
